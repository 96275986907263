var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "list-box" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "infinite-scroll",
              rawName: "v-infinite-scroll",
              value: _vm.queryData,
              expression: "queryData"
            }
          ],
          staticClass: "news-list"
        },
        [
          _c(
            "div",
            { staticClass: "list-title" },
            [
              _c(
                "el-badge",
                { staticClass: "item", attrs: { "is-dot": _vm.noRead } },
                [_vm._v("站内信")]
              ),
              _vm.newsList.length > 0
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "danger", plain: "", size: "mini" },
                      on: { click: _vm.allRead }
                    },
                    [_vm._v("全部已读")]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.newsList.length > 0
            ? _vm._l(_vm.newsList, function(news, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "news-item",
                    class: {
                      noRead: news.typeStatus == 0,
                      currentClick: _vm.currentClick == index
                    },
                    on: {
                      click: function($event) {
                        return _vm.choose(news.typeStatus, news.typeId, index)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "news-title" }, [
                      _c("i", { staticClass: "el-icon-message" }),
                      _vm._v(" " + _vm._s(JSON.parse(news.remark).title))
                    ]),
                    _c("div", { staticClass: "news-time" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("fTime")(JSON.parse(news.remark).issueTime)
                        )
                      )
                    ])
                  ]
                )
              })
            : [
                _c(
                  "div",
                  {
                    staticStyle: {
                      height: "calc(100% - 35px)",
                      overflow: "auto"
                    }
                  },
                  [_c("listNull", { attrs: { text: "还没有消息呢~" } })],
                  1
                )
              ]
        ],
        2
      ),
      _c("div", { staticClass: "news-detail" }, [
        _vm.currentClick > -1
          ? _c("div", [
              _c("div", { staticClass: "news-content-header" }, [
                _c("div", { staticClass: "news-content-title" }, [
                  _vm._v(" " + _vm._s(_vm.title) + " ")
                ]),
                _c("div", { staticClass: "news-content-time" }, [
                  _vm._v(" " + _vm._s(_vm._f("fTime")(_vm.time)) + " "),
                  _c("i", {
                    staticClass: "el-icon-delete",
                    attrs: { title: "删除消息" },
                    on: { click: _vm.deleteNews }
                  })
                ])
              ]),
              _c("div", { domProps: { innerHTML: _vm._s(_vm.content) } })
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }