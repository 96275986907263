<template>
 <div class="container">
   <div class="list-box">
     <div class="news-list" v-infinite-scroll="queryData">
       <div class="list-title">
         <el-badge :is-dot="noRead" class="item">站内信</el-badge>
         <el-button v-if="newsList.length > 0" type="danger" plain size="mini" @click="allRead">全部已读</el-button>
       </div>
       <template v-if="newsList.length > 0">
         <div class="news-item"
              :class="{noRead: news.typeStatus == 0,currentClick: currentClick == index}"
              v-for="(news, index) of newsList" :key="index"
              @click="choose(news.typeStatus, news.typeId, index)"
         >
           <div class="news-title"><i class="el-icon-message"></i> {{ JSON.parse(news.remark).title }}</div>
           <div class="news-time">{{ JSON.parse(news.remark).issueTime | fTime }}</div>
         </div>
       </template>
       <template v-else>
         <div style="height: calc(100% - 35px);overflow: auto;">
           <listNull text="还没有消息呢~" />
         </div>
       </template>
     </div>
     <div class="news-detail">
       <div v-if="currentClick > -1">
         <div class="news-content-header">
           <div class="news-content-title">
             {{ title }}
           </div>
           <div class="news-content-time">
             {{ time | fTime }}
             <i title="删除消息" @click="deleteNews" class="el-icon-delete"></i>
           </div>
         </div>
         <div v-html="content"></div>
       </div>

     </div>
   </div>
 </div>
</template>

<script>
import listNull from '@/components/listNull.vue'
import { queryMessageByUser, markMessageAsRead, deleteMessageByUserAndMsgId, markAllMessageAsRead } from '@/api/news.js'
import { rTime } from '@/components/tableCom/js/filter.js'
export default {
  name: 'newsShow',
  components: { listNull },
  filters: {
    fTime (time) {
      return rTime(time, 3)
    }
  },
  data () {
    return {
      currentClick: -1, // 左侧当前选中
      currentNews: {}, // 右侧当前展示
      currentId: '',
      title: '',
      time: '',
      content: '',
      noRead: false,
      newsList: [], // 消息列表
      page: 1,
      limit: 20,
      total: 1
    }
  },
  methods: {
    queryData () {
      if (this.newsList.length >= this.total) return
      queryMessageByUser({
        page: this.page,
        limit: this.limit
      }).then(res => {
        if (res.code === 0) {
          if (this.newsList.length >= res.data.total) {
            return
          }
          if (res.data.list.length > 0) {
            this.newsList.push(...res.data.list)
            this.total = res.data.total
          }
          if (res.data.list.length == this.limit) {
            this.page++
          }
        } else if(res.code == 401) {
          this.$message({
            type: 'error',
            message: '登录失效，请重新登录'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    allRead () {
      markAllMessageAsRead().then(res=>{
        if(res.code == 0) {
          this.newsList = []
          this.page = 1
          this.queryData()
        } else {
           this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    choose (status, id, index) {
//        console.log(status,id,index)
      this.currentClick = index
      this.currentId = id
      this.currentNews = this.newsList[index]
      this.title = JSON.parse(this.currentNews.remark).title
      this.time = JSON.parse(this.currentNews.remark).issueTime
      this.content = JSON.parse(this.currentNews.remark).content
//      console.log(this.currentNews)
      if (status == 0) {
        markMessageAsRead({ typeId: id }).then(res => {
          if (res.code === 0) {
            this.currentNews = this.newsList[index].typeStatus = 1
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            })
          }
        })
      }
    },
    deleteNews () {
//        console.log(this.currentId)
      this.$confirm('此操作不可撤销, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteMessageByUserAndMsgId({
          typeId: this.currentId
        }).then(res => {
          if (res.code === 0) {
            this.currentNews = {}
            this.currentClick = -1
            this.title = ''
            this.time = ''
            this.content = ''
            this.newsList = []
            this.queryData()
            this.$message({
              type: 'success',
              message: '删除成功'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            })
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "./css/newsShow";
</style>
